import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, { Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, Text, View,  TouchableOpacity, Image, Button } from 'react-native';
import HomeScreen from './Screens/Home';
import ReceiveStaffScreen from './Screens/ReceiveStaff';
import ReceiveInvoiceScreen from './Screens/ReceiveInvoice';
import ComplectStaffScreen from './Screens/ComplectStaff';
import ComplectInvoiceScreen from './Screens/ComplectInvoice';
import ComplectSubdivisionScreen from './Screens/ComplectSubdivision';
import logo from './assets/logo.svg';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


/**
 *  @brief Контекст общий для всех экранов
 */
const Stack = createNativeStackNavigator();

function StackScreen() {
  return (
    <Stack.Navigator initialRouteName="Home" screenOptions={{ }}>
        <Stack.Screen name="Home" options={{ title: 'Диана' }} component={HomeScreen}  />
        <Stack.Screen name="ReceiveStaff" options={{ title: 'Прием в обработку. Сотрудник' }} component={ReceiveStaffScreen}   />
        <Stack.Screen name="ReceiveInvoice" options={{ title: 'Прием в обработку. Квитанция' }} component={ReceiveInvoiceScreen}   />
        <Stack.Screen name="ComplectStaff" options={{ title: 'Комплектование. Сотрудник' }} component={ComplectStaffScreen} />
        <Stack.Screen name="ComplectInvoice" options={{ title: 'Комплектование. Квитанция' }} component={ComplectInvoiceScreen} />
        <Stack.Screen name="ComplectSubdivision" options={{ title: 'Комплектование. Ячейка' }} component={ComplectSubdivisionScreen} />
    </Stack.Navigator>
  );
}

const App = () => {
  return (
    <NavigationContainer onChange={(e) => console.log(e)}>
      <StackScreen/>
    </NavigationContainer>
  );
};

export default App;
