import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  body: {
    flex: 10,
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 10,
  },
  inputContainer: {
    flex: 2,
    height: 25,
    paddingTop: 20
  },
  inputContainer2: {
    width: 300,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
      height: 25,
      width: 200,
      fontSize: 12,
      marginRight: 10,
      paddingLeft: 10,
      borderColor: "#008c9a",
      borderWidth: 1,
      borderRadius: 5,
      flex: 1
  },
  button: {
    backgroundColor: "#008c9a",
    borderColor: "#008c9a",
    borderWidth: 1,
    borderRadius: 5,
    height: 25,
    width: 100,
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 12,
    color: '#fff',
    paddingTop: 3,
    paddingBottom: 2,
  },
  textContent: {
    flex: 7,
    alignItems: 'flex-start',
  },
  h1: {
      fontSize: 14,
      fontWeight: 'bold',
      paddingTop: 0,
      paddingBottom: 0
  },
  li: {
      fontSize: 12,
      paddingTop: 2,
      paddingBottom: 0
  },
  helpContent: {
    flex: 1,
    alignItems: 'flex-start',
  },
  error: {
      fontSize: 11,
      color: '#900',
      paddingTop: 0,
      paddingBottom: 5
  },
  help: {
      fontSize: 11,
      paddingBottom: 20
  },
  cleanContent: {
    flex: 1,
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: 310,
    marginBottom: 20
  },
  buttonGrey: {
    backgroundColor: "#fff",
    borderColor: "#777",
    borderWidth: 1,
    borderRadius: 5,
    height: 25,
    width: 100,
    alignItems: 'center',
  },
  buttonGreyText: {
    fontSize: 12,
    color: '#777',
    paddingTop: 3,
    paddingBottom: 2,
  },
});

export default styles;