import 'react-native-gesture-handler';
import React from 'react';
import { Text, View,  TouchableOpacity, TextInput, Keyboard } from 'react-native';
import appSettings from '../Settings';
import styles from './Styles/Receive';
import appTools from '../Tools';


/**
 *  Экран приема в обработку шаг квитанция
 */
export default class ReceiveInvoice extends React.Component {
    
    state = {
        invoiceCode: '',  // код квитанции
        scanCode: '',   // сканированный код
        invoice: false,   // данные квитанции
        error: false    // ошибка
    }
    
    /**
     *  @brief Конструктор
     */
    constructor(props) {
        super(props);
    }
    
    /**
     *  @brief добавляем отслеживание клавиатуры
     */
    componentDidMount(){
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide,
        );
        this.inputScanRef = React.createRef();
    }
    componentWillUnmount() {
        this.keyboardDidHideListener.remove();
    }
    _keyboardDidHide =()=> {
       if (this.inputScanRef.current)
        this.inputScanRef.current.focus();
    }
    
    /**
     *  @brief Сканирование
     */
    scan(value) {
        if (value.length>0) {
            this.setState({invoiceCode: value}, () => {
                this.invoiceSubmit();
            });
        }
        this.setState({scanCode: ''});
    }
    
    /**
     *  @brief Очистка
     */
    invoiceClean() {
        this.setState({invoiceCode: '', scanCode: '', error: false, invoice: false});
    }
    
    /**
     *  @brief ручной ввод
     */
    invoiceChange(value) {
        this.setState({invoiceCode: value}, () => {
            if (value.length==13) {
                this.invoiceSubmit();
            }
        });
    }
    
    /**
     *  @brief Проверка сотрудника
     */
    invoiceSubmit() {
        var self = this;
        self.setState({error: false});
        self.setState({invoice: false});
        fetch(appSettings.host + '/api/complect/v1/invoice/'+self.state.invoiceCode, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Origin': 'https://crm.dryclean.ru',
            'Imei': appSettings.imei,
            'X-Crm-Authorization': appSettings.token,
            'Content-Type': 'application/json'
          },
        })
        .then((response) => response.json())
        .then((json) => {
            if (!json.invoice) {
                if (json.message) {
                    self.setState({error: json.message});
                } else {
                    self.setState({error: 'Неизвестная ошибка, попробуйте еще раз'});
                }
                appTools.errorSignal();
            } else {
                self.setState({invoice: json.invoice});
                appTools.successSignal();
            }
        })
        .catch((error) => {
          self.setState({error: 'Произошла ошибка при соединении, попробуйте еще раз'});
          appTools.errorSignal();
        });
        Keyboard.dismiss();
        if (this.inputScanRef.current)
        this.inputScanRef.current.focus();
    }
    
    /**
     *  @brief Завершаем приемку
     */
    receiveConfirm() {
        var self = this;
        fetch(appSettings.host + '/api/complect/v1/add', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Origin': 'https://crm.dryclean.ru',
            'Imei': appSettings.imei,
            'X-Crm-Authorization': appSettings.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({'staff': self.props.route.params.staff.barcode, 'invoice': self.state.invoice.barcode})
        })
        .then((response) => response.json())
        .then((json) => {
            if (!json.result) {
                if (json.message) {
                    self.setState({error: json.message});
                } else {
                    self.setState({error: 'Неизвестная ошибка, попробуйте еще раз'});
                }
                appTools.errorSignal();
            } else {
                self.props.navigation.navigate('Home');
                appTools.successSignal();
                appTools.successSound();
            }
        })
        .catch((error) => {
          self.setState({error: 'Произошла ошибка при соединении, попробуйте еще раз'});
          appTools.errorSignal();
        });
        Keyboard.dismiss();
        if (this.inputScanRef.current)
            this.inputScanRef.current.focus();
    }

    render() {
      return (
            <View style={styles.body}>
               <View style={styles.inputContainer}>
                   <View style={styles.inputContainer2}>
                       <TextInput 
                            onSubmitEditing={(event) => this.scan(event.nativeEvent.text)} 
                            ref={this.inputScanRef} 
                            value={this.state.scanCode} 
                            showSoftInputOnFocus={false} 
                            style={{display: 'none'}}
                            autoFocus={true} 
                       />
                       <TextInput 
                            onChangeText={(value) => this.invoiceChange(value)} 
                            onSubmitEditing={() => this.invoiceSubmit()}  
                            value={this.state.invoiceCode} 
                            style={styles.input} 
                            keyboardType="number-pad"
                       />
                       <TouchableOpacity onPress={() => this.invoiceSubmit()} style={styles.button}>
                        <Text style={styles.buttonText}>Применить</Text>
                       </TouchableOpacity>
                   </View>
                   <Text style={[styles.error, !this.state.error && {display: 'none'} ]}>{this.state.error}</Text>
               </View>               
               <View style={styles.textContent} >
                   <Text style={styles.h1}>Сотрудник </Text>
                   <Text style={styles.li}>Штрих-код: {this.props.route.params.staff.barcode}</Text>
                   <Text style={styles.li}>ФИО: {this.props.route.params.staff.name}</Text>
                   <View style={!this.state.invoice ? {display: 'none'} : {marginTop: 10}} >
                       <Text style={styles.h1}>Квитанция </Text>
                       <Text style={styles.li}>Штрих-код: {this.state.invoice.barcode}</Text>
                       <Text style={styles.li}>Номер: {this.state.invoice.number}</Text>
                       <Text style={styles.li}>Сумма: {this.state.invoice.inspector_total}</Text>
                       <Text style={styles.li}>Изделий: {this.state.invoice.count_stuff}</Text>
                   </View>
               </View>
               <View style={styles.cleanContent}>
                    <View style={this.state.invoice ? {width: 90} : {width: 200}} />
                    <TouchableOpacity onPress={() => this.invoiceClean()} style={styles.buttonGrey}>
                        <Text style={styles.buttonGreyText}>Очистить</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.receiveConfirm()} style={[styles.button, this.state.invoice? {marginLeft: 10} : {display: 'none'}]}>
                        <Text style={styles.buttonText}>Подтвердить</Text>
                    </TouchableOpacity>
               </View>
            </View>
      );
    }
};