import 'react-native-gesture-handler';
import React, { Component } from 'react';
import * as Network from 'expo-network';

/**
 *  @brief Настройки общие для всех экранов
 */
var AppSettings = function() {
    this.token = '';                          // токен авторизации
    this.imei = 'unknown';                            // Imei
    this.host = 'https://dev.crm.dryclean.ru' // endpoint
    
    /**
     *  @brief Получаем токен авторизации
     */
    this.getToken = function () {
        var self = this;
        fetch(self.host + '/api/complect/v1/auth', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({'imei': self.imei})
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.token) {
                self.token = json.token;
            }
        })
        .catch((error) => {
            self.token = 'error';
        });
    }
    
    this.init = function() {
        var self = this;
        /**
         *  @brief Получаем Imei
         */
        Network.getMacAddressAsync().then(mac => {
            if (mac) {
                self.imei = mac;
            }
            self.getToken();
        });
    }
    
    this.init();
};

const appSettings = new AppSettings();

export default appSettings;
