import 'react-native-gesture-handler';
import React from 'react';
import { Text, View,  TouchableOpacity, TextInput, Keyboard } from 'react-native';
import appSettings from '../Settings';
import styles from './Styles/Complect';
import appTools from '../Tools';


/**
 *  Экран комплектования шаг ячейки
 */
export default class ComplectSubdivision extends React.Component {
    
    state = {
        subdivisionCode: '',    // код квитанции
        scanCode: '',           // сканированный код
        subdivision: false,     // данные квитанции
        error: false            // ошибка
    }
    
    /**
     *  @brief Конструктор
     */
    constructor(props) {
        super(props);
    }
    
    /**
     *  @brief добавляем отслеживание клавиатуры
     */
    componentDidMount(){
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide,
        );
        this.inputScanRef = React.createRef();
    }
    componentWillUnmount() {
        this.keyboardDidHideListener.remove();
    }
    _keyboardDidHide =()=> {
       if (this.inputScanRef.current)
        this.inputScanRef.current.focus();
    }
    
    /**
     *  @brief Сканирование
     */
    scan(value) {
        if (value.length>0) {
            this.setState({subdivisionCode: value}, () => {
                this.subdivisionSubmit();
            });
        }
        this.setState({scanCode: ''});
    }
    
    /**
     *  @brief Очистка
     */
    subdivisionClean() {
        this.setState({subdivisionCode: '', scanCode: '', error: false, subdivision: false});
    }
    
    /**
     *  @brief ручной ввод
     */
    subdivisionChange(value) {
        this.setState({subdivisionCode: value}, () => {
            if (value.length==13) {
                this.subdivisionSubmit();
            }
        });
    }
    
    /**
     *  @brief Проверка ячейки
     */
    subdivisionSubmit() {
        var self = this;
        self.setState({error: false});
        self.setState({subdivision: false});
        fetch(appSettings.host + '/api/complect/v1/cell/'+self.state.subdivisionCode, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Origin': 'https://crm.dryclean.ru',
            'Imei': appSettings.imei,
            'X-Crm-Authorization': appSettings.token,
            'Content-Type': 'application/json'
          },
        })
        .then((response) => response.json())
        .then((json) => {
            if (!json.subdivision) {
                if (json.message) {
                    self.setState({error: json.message});
                } else {
                    self.setState({error: 'Неизвестная ошибка, попробуйте еще раз'});
                }
                appTools.errorSignal();
            } else {
                self.setState({subdivision: json.subdivision});
                appTools.successSignal();
            }
        })
        .catch((error) => {
          self.setState({error: 'Произошла ошибка при соединении, попробуйте еще раз'});
          appTools.errorSignal();
        });
        Keyboard.dismiss();
        if (this.inputScanRef.current)
        this.inputScanRef.current.focus();
    }
    
    /**
     *  @brief Завершаем комплектовку
     */
    complectConfirm() {
        var self = this;
        fetch(appSettings.host + '/api/complect/v1/send', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Origin': 'https://crm.dryclean.ru',
            'Imei': appSettings.imei,
            'X-Crm-Authorization': appSettings.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({'staff': self.props.route.params.staff.barcode, 'invoice': self.props.route.params.invoice.barcode, 'cell': self.state.subdivision.barcode})
        })
        .then((response) => response.json())
        .then((json) => {
            if (!json.registerId) {
                if (json.message) {
                    self.setState({error: json.message});
                } else {
                    self.setState({error: 'Неизвестная ошибка, попробуйте еще раз'});
                }
                appTools.errorSignal();
            } else {
                appTools.successSignal();
                appTools.successSound();
                self.props.navigation.navigate('Home');
            }
        })
        .catch((error) => {
          self.setState({error: 'Произошла ошибка при соединении, попробуйте еще раз'});
          appTools.errorSignal();
        });
        Keyboard.dismiss();
        if (this.inputScanRef.current)
            this.inputScanRef.current.focus();
    }

    render() {
      return (
            <View style={styles.body}>
               <View style={styles.inputContainer}>
                   <View style={styles.inputContainer2}>
                       <TextInput 
                            onSubmitEditing={(event) => this.scan(event.nativeEvent.text)} 
                            ref={this.inputScanRef} 
                            value={this.state.scanCode} 
                            showSoftInputOnFocus={false} 
                            style={{display: 'none'}}
                            autoFocus={true} 
                       />
                       <TextInput 
                            onChangeText={(value) => this.subdivisionChange(value)} 
                            onSubmitEditing={() => this.subdivisionSubmit()}  
                            value={this.state.subdivisionCode} 
                            style={styles.input} 
                            keyboardType="number-pad"
                       />
                       <TouchableOpacity onPress={() => this.subdivisionSubmit()} style={styles.button}>
                        <Text style={styles.buttonText}>Применить</Text>
                       </TouchableOpacity>
                   </View>
                   <Text style={[styles.error, !this.state.error && {display: 'none'} ]}>{this.state.error}</Text>
               </View>               
               <View style={styles.textContent} >
                   <Text style={styles.h1}>Сотрудник </Text>
                   <Text style={styles.li}>Штрих-код: {this.props.route.params.staff.barcode}</Text>
                   <Text style={styles.li}>ФИО: {this.props.route.params.staff.name}</Text>
                   <View style={{marginTop: 10}} >
                       <Text style={styles.h1}>Квитанция </Text>
                       <Text style={styles.li}>Штрих-код: {this.props.route.params.invoice.barcode}</Text>
                       <Text style={styles.li}>Номер: {this.props.route.params.invoice.number}</Text>
                       <Text style={styles.li}>Сумма: {this.props.route.params.invoice.inspector_total}</Text>
                       <Text style={styles.li}>Изделий: {this.props.route.params.invoice.count_stuff}</Text>
                   </View>
                   <View style={!this.state.subdivision ? {display: 'none'} : {marginTop: 10}} >
                       <Text style={styles.h1}>Ячейка </Text>
                       <Text style={styles.li}>Штрих-код: {this.state.subdivision.barcode}</Text>
                       <Text style={styles.li}>Пункт Выдачи: {this.state.subdivision.name}</Text>
                   </View>
               </View>
               <View style={styles.cleanContent}>
                    <View style={this.state.subdivision ? {width: 90} : {width: 200}} />
                    <TouchableOpacity onPress={() => this.subdivisionClean()} style={styles.buttonGrey}>
                        <Text style={styles.buttonGreyText}>Очистить</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.complectConfirm()} style={[styles.button, this.state.subdivision? {marginLeft: 10} : {display: 'none'}]}>
                        <Text style={styles.buttonText}>Подтвердить</Text>
                    </TouchableOpacity>
               </View>
            </View>
      );
    }
};