import 'react-native-gesture-handler';
import React from 'react';
import { Text, View,  TouchableOpacity } from 'react-native';
import styles from './Styles/Home';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
      return (
            <View style={styles.body}>
                <View style={styles.buttonContainer}>
                   <TouchableOpacity onPress={() => this.props.navigation.navigate('ComplectStaff')} style={styles.buttonMain}>
                    <Text style={styles.buttonMainText}>Комплектование</Text>
                   </TouchableOpacity>
                </View>
                <View style={styles.buttonContainer}>
                   <TouchableOpacity onPress={() => this.props.navigation.navigate('ReceiveStaff')} style={styles.buttonMain}>
                    <Text style={styles.buttonMainText}>Прием в обработку</Text>
                   </TouchableOpacity>
                </View>
            </View>
      );
    }
};
