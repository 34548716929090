import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    flex: 9,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    flex: 4,
    paddingTop: 30,
  },
  buttonMain: {
    backgroundColor: "#fff",
    borderColor: "#008c9a",
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    width: 280
  },
  buttonMainText: {
    fontSize: 25,
    color: '#008c9a',
    paddingTop: 50,
    paddingBottom: 50,
  }, 
});

export default styles;