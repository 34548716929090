import 'react-native-gesture-handler';
import { Vibration } from 'react-native';
import { Audio } from 'expo-av';
import stopSound from './assets/sound/stop.mp3';
import successSound from './assets/sound/success.mp3';


/**
 *  @brief Вспомогательные функции
 */
var AppTools = function() {
    
    this.soundError = new Audio.Sound();
    try {
      this.soundError.loadAsync(stopSound);
      // Your sound is playing!
    } catch (error) {
      // An error occurred!
    }
    this.soundSuccess = new Audio.Sound();
    try {
      this.soundSuccess.loadAsync(successSound);
      // Your sound is playing!
    } catch (error) {
      // An error occurred!
    }
    
    /**
     *  @brief сигнализируем об успехе
     */
    this.successSignal = function () {
        Vibration.vibrate(100);
    }
    
    /**
     *  @brief сигнализируем о добавлении
     */
    this.successSound = function () {
        var self = this;
        try {
          self.soundSuccess.setPositionAsync(0).then(()=>{
              self.soundSuccess.playAsync();
          })
          // Your sound is playing!
        } catch (error) {
          // An error occurred!
        }
    }
    
    /**
     *  @brief Сигнализируем об ошибке
     */
    this.errorSignal = function() {
        var self = this;
        Vibration.vibrate([0, 100, 100, 300]);
        try {
          self.soundError.setPositionAsync(0).then(()=>{
              self.soundError.playAsync();
          })
          // Your sound is playing!
        } catch (error) {
          // An error occurred!
        }
    }
};

const appTools = new AppTools();

export default appTools;