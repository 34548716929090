import 'react-native-gesture-handler';
import React from 'react';
import { Text, View,  TouchableOpacity, TextInput, Keyboard } from 'react-native';
import appSettings from '../Settings';
import styles from './Styles/Receive';
import appTools from '../Tools';


/**
 *  Экран приема в обработку шаг сотрудник
 */
export default class ReceiveStaff extends React.Component {
    
    state = {
        staffCode: '',  // код сотрудника
        scanCode: '',   // сканированный код
        staff: false,   // данные сотрудника
        error: false    // ошибка
    }
    
    /**
     *  @brief Конструктор
     */
    constructor(props) {
        super(props);
    }
    
    /**
     *  @brief добавляем отслеживание клавиатуры
     */
    componentDidMount(){
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide,
        );
        this.inputScanRef = React.createRef();
    }
    componentWillUnmount() {
        this.keyboardDidHideListener.remove();
    }
    _keyboardDidHide =()=> {
       if (this.inputScanRef.current)
        this.inputScanRef.current.focus();
    }
    
    /**
     *  @brief Сканирование
     */
    scan(value) {
        if (value.length>0) {
            this.setState({staffCode: value}, () => {
                this.staffSubmit();
            });
        }
        this.setState({scanCode: ''});
    }
    
    /**
     *  @brief Очистка
     */
    staffClean() {
        this.setState({staffCode: '', scanCode: '', error: false, staff: false});
    }
    
    /**
     *  @brief ручной ввод
     */
    staffChange(value) {
        this.setState({staffCode: value}, () => {
            if (value.length==13) {
                this.staffSubmit();
            }
        });
    }
    
    /**
     *  @brief Проверка сотрудника
     */
    staffSubmit() {
        var self = this;
        self.setState({error: false});
        self.setState({staff: false});
        fetch(appSettings.host + '/api/complect/v1/staff/'+self.state.staffCode, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Origin': 'https://crm.dryclean.ru',
            'Imei': appSettings.imei,
            'X-Crm-Authorization': appSettings.token,
            'Content-Type': 'application/json'
          },
        })
        .then((response) => response.json())
        .then((json) => {
            if (!json.staff) {
                if (json.message) {
                    self.setState({error: json.message});
                } else {
                    self.setState({error: 'Неизвестная ошибка, попробуйте еще раз'});
                }
                appTools.errorSignal();
            } else {
                this.props.navigation.navigate('ReceiveInvoice', { staff: json.staff });
                appTools.successSignal();
            }
        })
        .catch((error) => {
          self.setState({error: 'Произошла ошибка при соединении, попробуйте еще раз'});
          appTools.errorSignal();
        });
        Keyboard.dismiss();
        if (this.inputScanRef.current)
            this.inputScanRef.current.focus();
    }

    render() {
      return (
            <View style={styles.body}>
               <View style={styles.inputContainer}>
                   <View style={styles.inputContainer2}>
                       <TextInput 
                            onSubmitEditing={(event) => this.scan(event.nativeEvent.text)} 
                            ref={this.inputScanRef} 
                            value={this.state.scanCode} 
                            showSoftInputOnFocus={false} 
                            style={{display: 'none'}}
                            autoFocus={true} 
                       />
                       <TextInput 
                            onChangeText={(value) => this.staffChange(value)} 
                            onSubmitEditing={() => this.staffSubmit()}  
                            value={this.state.staffCode} 
                            style={styles.input} 
                            keyboardType="number-pad"
                       />
                       <TouchableOpacity onPress={() => this.staffSubmit()} style={styles.button}>
                        <Text style={styles.buttonText}>Применить</Text>
                       </TouchableOpacity>
                   </View>
                   <Text style={[styles.error, !this.state.error && {display: 'none'} ]}>{this.state.error}</Text>
               </View>               
               <View style={[styles.textContent, (this.state.error || this.state.staff) && {display: 'none'}]} >
                   <Text style={styles.h1}>Выполните последовательно следующие операции:</Text>
                   <Text style={styles.li}>1. Отсканируйте штрих-код на вашем бейджике.</Text>
                   <Text style={styles.li}>2. Отсканируйте штрих-код квитанции.</Text>
                   <Text style={styles.li}>3. Подтвердите данные.</Text>
                   <Text style={styles.li}>4. Передайте изделия из квитанции в обработку.</Text>
               </View>
               <View style={[styles.textContent, !this.state.staff && {display: 'none'}]} >
                   <Text style={styles.h1}>Сотрудник </Text>
                   <Text style={styles.li}>Штрих-код: {this.state.staff.barcode}</Text>
                   <Text style={styles.li}>ФИО: {this.state.staff.name}</Text>
               </View>
               <View style={[styles.helpContent, (this.state.error || this.state.staff) && {display: 'none'}]}>
                    <Text style={styles.help}>Если штрих-код не распознается - введите цифры штрих-код вручную.</Text>
               </View>
               <View style={[styles.cleanContent, !this.state.error && {display: 'none'}]}>
                    <View style={{width: 200}} />
                    <TouchableOpacity onPress={() => this.staffClean()} style={styles.buttonGrey}>
                        <Text style={styles.buttonGreyText}>Очистить</Text>
                    </TouchableOpacity>
               </View>
            </View>
      );
    }
};